/**
 * Format unix timestamp to ISO8601
 * @param formatStr yyyy mm dd h m s
 * @param timestamp
 */

export const formatDate = (formatStr: string, timestamp: number): string => {
	const a = new Date(timestamp * 1000)
	const o: { [key in string]: any } = {
		'y+': a.getFullYear(),
		'm+': a.getMonth() + 1, //月份
		'd+': a.getDate(), //日
		'h+': a.getHours(), //小时
		'i+': a.getMinutes(), //分
		's+': a.getSeconds() //秒
	}
	for (const k in o) {
		if (new RegExp('(' + k + ')', 'i').test(formatStr)) {
			formatStr = formatStr.replace(
				RegExp.$1,
				o[k].toString().length < 2 ? '0' + o[k] : o[k]
			)
		}
	}
	return formatStr
}
