
import { Component, Watch } from 'vue-property-decorator'
import Emitter from '@/utils/emitter'
import { ElForm } from 'element-ui/types/form'
import ZButton from '@/components/base/z-button.vue'
import { isWechat, getUserRole } from '@/utils/utils'
import { formatDate } from '@/utils/date'
@Component({
	components: { ZButton },
	filters: {
		formatDate(value: number) {
			return value ? formatDate('yyyy-MM-dd h:i:s', value) : '-'
		}
	}
})
export default class extends Emitter {
	public $refs!: {
		form: ElForm
		audio: HTMLAudioElement
	}
	private isWechat = false
	private isLoading = false
	private inboundList: any = []
	private editForm = {
		code: ''
	}
	private userRoles: any = {}
	async getUserRole() {
		this.userRoles = await getUserRole()
	}
	private validateForm = {
		code: { required: true, message: '请输入入仓单号', trigger: 'blur' }
	}
	async initJSSdk() {
		if (!this.isWechat) return false
		const url = location.href.split('#')[0]
		const { data } = await this.$axios.post('v1/jobline/js-sdk-config', {
			url
		})
		this.$wx.config({
			debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
			...data,
			jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
		})
	}
	created() {
		this.getUserRole()
		this.isWechat = isWechat()
	}
	formatUserRole(v: number) {
		return (
			this.userRoles[v] || {
				tag_type: 'warning',
				name: '未知'
			}
		)
	}
	async scanBarcode() {
		if (!this.$wx) return false
		await this.initJSSdk()
		this.$wx.ready(() => {
			this.$wx.scanQRCode({
				needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
				scanType: ['barCode'], // 可以指定扫二维码还是一维码，默认二者都有
				success: (res: any) => {
					const result = res.resultStr.split(',')
					this.editForm.code = result[result.length - 1]
					this.doInbound()
				}
			})
		})
	}
	async removeInbound({ row, $index }: any) {
		const isConfirm = await this.$confirm('确认删除？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (!isConfirm) return false
		await this.$axios.delete(`v1/jobline/waybill/inbound/${row.waybill}`)
		this.inboundList.splice($index, 1)
	}
	async doInbound() {
		const isValidate = await this.$refs['form'].validate().catch(() => false)
		if (!isValidate) return
		const { code } = this.editForm
		const { data } = await this.$axios
			.post('v1/jobline/waybill/inbound', {
				waybill: code
			})
			.finally(() => {
				this.isLoading = false
			})
		const { total_package } = data
		if (total_package === 0) {
			this.$message({
				showClose: true,
				center: true,
				offset: 120,
				duration: 4500,
				type: 'success',
				message: '这是一个首单新用户哦~'
			})
		}
		this.$message.success('入仓成功')
		this.editForm.code = ''
		this.inboundList.unshift(data)
		this.$refs.audio.play()
	}
}
